<template>
  <span>{{ getDate() }}</span>
</template>
<script setup>
const props = defineProps({
  unit: { type: String, required: false, default: "" },
  delay: { type: String, required: false, default: "" },
});
const getDate = () => {
  const date = new Date();
  props.unit === "month"
    ? date.setMonth(date.getMonth() + parseInt(props.delay))
    : date.setFullYear(date.getFullYear() + parseInt(props.delay));
  return props.unit === "month"
    ? date.toLocaleString("default", { month: "long" })
    : date.toLocaleString("default", { year: "numeric" });
};
</script>
